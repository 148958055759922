import styles from './styles.module.scss';
import { Props } from './types';

import { SvgIconComponent } from '@mui/icons-material';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddIcon from '@mui/icons-material/Add';
import ArchiveIcon from '@mui/icons-material/Archive';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropDownCircleSharpIcon from '@mui/icons-material/ArrowDropDownCircleSharp';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArticleIcon from '@mui/icons-material/Article';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CheckIcon from '@mui/icons-material/Check';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CircleIcon from '@mui/icons-material/Circle';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import CopyrightIcon from '@mui/icons-material/Copyright';
import CreateIcon from '@mui/icons-material/Create';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DehazeIcon from '@mui/icons-material/Dehaze';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import ErrorIcon from '@mui/icons-material/Error';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import GradingIcon from '@mui/icons-material/Grading';
import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LabelIcon from '@mui/icons-material/Label';
import LaunchIcon from '@mui/icons-material/Launch';
import LinkIcon from '@mui/icons-material/Link';
import ListIcon from '@mui/icons-material/List';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MailIcon from '@mui/icons-material/Mail';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NumbersIcon from '@mui/icons-material/Numbers';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import PeopleIcon from '@mui/icons-material/People';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RemoveIcon from '@mui/icons-material/Remove';
import ReplayIcon from '@mui/icons-material/Replay';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ScheduleIcon from '@mui/icons-material/Schedule';
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import SupportIcon from '@mui/icons-material/Support';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import TableChartIcon from '@mui/icons-material/TableChart';
import TaskIcon from '@mui/icons-material/Task';
import TextsmsIcon from '@mui/icons-material/Textsms';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import TitleIcon from '@mui/icons-material/Title';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import TripOriginTwoToneIcon from '@mui/icons-material/TripOriginTwoTone';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import WarningIcon from '@mui/icons-material/Warning';
import WebStoriesOutlinedIcon from '@mui/icons-material/WebStoriesOutlined';
import clsx from 'clsx';

export type CustomSvgIconComponent = React.FC<Props>;

const createIcon = (SvgIcon: SvgIconComponent) => {
  const Icon: CustomSvgIconComponent = (props) => {
    return (
      <SvgIcon
        className={clsx(
          props.className,
          props.color ? styles[props.color] : null,
        )}
        onClick={props.onClick}
        component={SvgIcon}
        style={{
          height: props.size ? `${props.size}px` : undefined,
          width: props.size ? `${props.size}px` : undefined,
          cursor: props.cursor,
        }}
      />
    );
  };

  return Icon;
};

export const Add = createIcon(AddIcon);
export const Remove = createIcon(RemoveIcon);
export const AccessTimeFilled = createIcon(AccessTimeFilledIcon);
export const AccountCircle = createIcon(AccountCircleIcon);
export const Create = createIcon(CreateIcon);
export const Clear = createIcon(ClearIcon);
export const Close = createIcon(CloseIcon);
export const Dehaze = createIcon(DehazeIcon);
export const Delete = createIcon(DeleteIcon);
export const ArrowBack = createIcon(ArrowBackIcon);
export const ArrowForward = createIcon(ArrowForwardIcon);
export const ArrowForwardOutlined = createIcon(ArrowForwardOutlinedIcon);
export const ArrowUp = createIcon(KeyboardArrowUpIcon);
export const ArrowDown = createIcon(KeyboardArrowDownIcon);
export const ArrowDownward = createIcon(ArrowDownwardIcon);
export const ArrowLeft = createIcon(KeyboardArrowLeftIcon);
export const ArrowRight = createIcon(KeyboardArrowRightIcon);
export const ArrowUpward = createIcon(ArrowUpwardIcon);
export const Replay = createIcon(ReplayIcon);
export const PanoramaFishEye = createIcon(PanoramaFishEyeIcon);
export const Check = createIcon(CheckIcon);
export const CheckCircle = createIcon(CheckCircleIcon);
export const ChevronLeft = createIcon(ChevronLeftIcon);
export const ChevronRight = createIcon(ChevronRightIcon);
export const CopyAll = createIcon(CopyAllIcon);
export const Copyright = createIcon(CopyrightIcon);
export const Help = createIcon(HelpIcon);
export const Link = createIcon(LinkIcon);
export const List = createIcon(ListIcon);
export const LocalPhone = createIcon(LocalPhoneIcon);
export const LocationOn = createIcon(LocationOnIcon);
export const Launch = createIcon(LaunchIcon);
export const Circle = createIcon(CircleIcon);
export const Done = createIcon(DoneIcon);
export const Edit = createIcon(EditIcon);
export const Error = createIcon(ErrorIcon);
export const Email = createIcon(EmailIcon);
export const EventNote = createIcon(EventNoteIcon);
export const ExpandLess = createIcon(ExpandLessIcon);
export const ExpandMore = createIcon(ExpandMoreIcon);
export const ExitToApp = createIcon(ExitToAppIcon);
export const FilterAlt = createIcon(FilterAltIcon);
export const Grading = createIcon(GradingIcon);
export const ArrowDropUp = createIcon(ArrowDropUpIcon);
export const ArrowDropDown = createIcon(ArrowDropDownIcon);
export const CalendarToday = createIcon(CalendarTodayIcon);
export const CheckBox = createIcon(CheckBoxIcon);
export const CheckBoxOutlineBlank = createIcon(CheckBoxOutlineBlankIcon);
export const Search = createIcon(SearchIcon);
export const RadioButtonUnchecked = createIcon(RadioButtonUncheckedIcon);
export const TripOriginTwoTone = createIcon(TripOriginTwoToneIcon);
export const Task = createIcon(TaskIcon);
export const Textsms = createIcon(TextsmsIcon);
export const ThumbUpAlt = createIcon(ThumbUpAltIcon);
export const TouchApp = createIcon(TouchAppIcon);
export const Mail = createIcon(MailIcon);
export const Menu = createIcon(MenuIcon);
export const MenuOpen = createIcon(MenuOpenIcon);
export const MoreHoriz = createIcon(MoreHorizIcon);
export const NoteAdd = createIcon(NoteAddIcon);
export const OpenInNew = createIcon(OpenInNewIcon);
export const DateRange = createIcon(DateRangeIcon);
export const FactCheck = createIcon(FactCheckIcon);
export const LocationCity = createIcon(LocationCityIcon);
export const MedicalServices = createIcon(MedicalServicesIcon);
export const People = createIcon(PeopleIcon);
export const Schedule = createIcon(ScheduleIcon);
export const SaveAlt = createIcon(SaveAltIcon);
export const Send = createIcon(SendIcon);
export const SettingsOutlined = createIcon(SettingsOutlinedIcon);
export const SpaceDashboard = createIcon(SpaceDashboardIcon);
export const SwapVert = createIcon(SwapVertIcon);
export const Info = createIcon(InfoIcon);
export const Warning = createIcon(WarningIcon);
export const WebStoriesOutlined = createIcon(WebStoriesOutlinedIcon);
export const Archive = createIcon(ArchiveIcon);
export const ArrowDropDownCircleSharp = createIcon(
  ArrowDropDownCircleSharpIcon,
);
export const Article = createIcon(ArticleIcon);
export const CheckBoxRounded = createIcon(CheckBoxRoundedIcon);
export const Numbers = createIcon(NumbersIcon);
export const RadioButtonChecked = createIcon(RadioButtonCheckedIcon);
export const Title = createIcon(TitleIcon);
export const FileUploadOutlined = createIcon(FileUploadOutlinedIcon);
export const FileDownloadOutlined = createIcon(FileDownloadOutlinedIcon);
export const Description = createIcon(DescriptionIcon);
export const Equalizer = createIcon(EqualizerIcon);
export const Label = createIcon(LabelIcon);
export const Notifications = createIcon(NotificationsIcon);
export const PeopleAlt = createIcon(PeopleAltIcon);
export const PlaylistAdd = createIcon(PlaylistAddIcon);
export const PlaylistAddCheck = createIcon(PlaylistAddCheckIcon);
export const UploadFile = createIcon(UploadFileIcon);
export const Support = createIcon(SupportIcon);
export const AutoGraph = createIcon(AutoGraphIcon);
export const TableChart = createIcon(TableChartIcon);
export { DataCheck } from './DataCheck';
export { SortNavigation } from './SortNavigation';
export { SortNavigationAsc } from './SortNavigationAsc';
export { SortNavigationDesc } from './SortNavigationDesc';
